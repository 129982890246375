<template>
    <ul class="info-list">
        <li v-for="item in infoList" :key="item.informationid" class="info-item">
            <router-link class="info-link" :to="`/news/detail?id=${item.informationid}`">
                <img class="info-img" :src="item.img" :alt="item.title">
                <span class="info-name text-ellipsis">{{ item.title }}</span>
            </router-link>
            <p class="info-price">
                <span class="info-price-item">
                    出荷时间：
                    {{ item.publishtype === '1' ? '未定' : (`${item.publishyear}年` + (item.publishtime === 'q0' ? '未定' : item.publishtime)) }}
                </span>
                <span class="info-price-item">定价：{{ item.rmb }}元</span>
            </p>
            <p class="info-series">
                <span class="info-series-name">{{ item.seriestitle }}</span>
                <span class="info-series-date">{{ item.ontime }} <i class="icon icon-more" /></span>
            </p>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'InfoList',
    props: {
        infoList: {
            type: Array,
            required: true,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
    .info-item {
        display: inline-block;
        vertical-align: top;
        width: 285px;
        margin-right: 20px;
        margin-bottom: 20px;
        background-color: $white;
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
    .info-img {
        display: block;
        @include widthHeight(285px, 285px);
    }
    .info-name {
        padding: 10px;
        display: block;
        height: 35px;
        font-size: 14px;
    }
    .info-price {
        border-bottom: 1px solid $gray;
        padding-bottom: 10px;
    }
    .info-price-item {
        padding: 6px 10px;
        display: block;
        color: $light;
    }
    .info-series {
        height: 47px;
        padding: 16px 10px;
        text-align: justify;
    }
    .info-series-date {
        float: right;
        .icon {
            margin-top: -1px;
        }
    }
</style>
