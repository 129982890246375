import Http from './http'

// 1.资讯系列用于下拉筛选
export function getSeries() {
    return Http({
        url: '/home/getSeries',
        method: 'get'
    })
}

// 2.资讯列表，可传: seriesid, sex(0 全部  1无2男3女),page
export function getInformationList(params) {
    return Http({
        url: '/home/getInformationList',
        method: 'get',
        params
    })
}

// 3.资讯详情，必传: informationid
export function getInformationDetail(params) {
    return Http({
        url: '/home/getInformationDetail',
        method: 'post',
        params
    })
}

// 4.是否关注了 该资讯或者商品，必传: paramid（资讯的时候 是informationid,商品的时候 是commodityid），type（1 资讯 2 商品）
// @response: "status": 2  //1关注了 2 未关注
export function iscollection(data) {
    return Http({
        url: '/home/iscollection',
        method: 'post',
        data
    })
}

// 5.关注（取消关注）该资讯或者商品，必传: paramid（资讯的时候 是informationid,商品的时候 是commodityid），type（1 资讯 2 商品）
// @response: "status": 2  //1关注了 2 取消关注
export function collection(data) {
    return Http({
        url: '/home/collection',
        method: 'post',
        data
    })
}
